import { useState } from "react"

import { ReactivateBlockerDialog } from "src/components/FeatureBlockers/ReactivateBlockerDialog"
import { EnabledHomesList } from "src/components/ResponseService/EnabledHomesList"
import { THome } from "src/data/homes/types/homeTypes"
import { useGetUser } from "src/data/user/hooks/useGetUser"
import { SUBSCRIPTION_STATUS } from "src/data/user/user"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import { MButton } from "src/ui/Button/MButton"
import { MainView } from "src/ui/Layout/MainView"

export function ResponseServiceHomesList({
  homes,
  homesCount,
}: {
  homes: Pick<
    THome,
    | "home_id"
    | "organization_id"
    | "name"
    | "disturbance_monitoring"
    | "response_service"
  >[]
  homesCount: number
}) {
  const { navigate } = useRouter()

  const user = useGetUser()
  const [reactivateDialogOpen, setReactivateDialogOpen] = useState(false)
  const { t, langKeys } = useTranslate()

  function handleActivateResponseServiceClick() {
    if (user.subscription_status !== SUBSCRIPTION_STATUS.active) {
      return setReactivateDialogOpen(true)
    }
    navigate(
      Routes.ActivateResponseService.location({
        provider: homes[0]?.response_service?.provider,
      })
    )
  }

  return (
    <MainView
      metaTitle={t(langKeys.response_service)}
      title={t(langKeys.minut_response_service)}
      titleBarProps={{
        description: t(langKeys.response_service_description),
        actionBar: (
          <MButton onClick={handleActivateResponseServiceClick}>
            {t(langKeys.add_home)}
          </MButton>
        ),
      }}
    >
      <EnabledHomesList homes={homes} homesCount={homesCount} />

      <ReactivateBlockerDialog
        open={reactivateDialogOpen}
        onClose={() => setReactivateDialogOpen(false)}
      />
    </MainView>
  )
}
